<template>
    <div class="switcherInput" :class="{'switcherInput__disabled':disabled}">
        <div class="switcherInput__label" v-if="label">
            {{ label }}
        </div>
        <label class="switcherInput__holder">
            <div class="switcherInput__text switcherInput__text_off" v-if="off">{{off}}</div>
            <input type="checkbox"
                :id="id"
                :checked="value"
                @change="$emit('input', $event.target.checked)" 
            >
            <div class="switcherInput__decor" :class="['switcherInput__decor_size_'+size]"></div>
            <div class="switcherInput__text switcherInput__text_on" v-if="on">{{on}}</div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'CustomSwitchInput',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        id: {
            type: [String, Number],
            default: ''
        },
        on: {
            type: String,
            default: 'on'
        },
        off: {
            type: String,
            default: 'off'
        },
        label: {
            type: String,
            default: ''
        },
        
        size: {
            type: String,
            default: 'Large'
        }
    },
}
</script>

<style lang="scss">
.switcherInput {
    margin-bottom: 5px;

    &__disabled {
        opacity: .3;
    }
    &__label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #919197;
        margin-bottom: 8px;
        display: inline-block;
    }
    &__holder { 
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }
    &__text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        &_off {
            margin-right: 12px;
        }
        &_on {
            margin-left: 12px;
        }
    }

    &__decor {
        width: 64px;
        height: 32px;
        position: relative;
        background: #CBCCCF;
        border-radius: 60px;
        cursor: pointer;
        &::after {
            display: block;
            content: '';
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 2px;
            margin: auto;
            transition: all .3s;
        }

        &_size{
            &_small {
                width: 43px;
                height: 23px;
                border-radius: 40px;
                &::after {
                    width: 20px;
                    height: 20px;
                }
            }
            &_xLarge {
                width: 106px;
                height: 53px;
                &::after {
                    width: 50px;
                    height: 50px;
                }
            }

        }
    }

    input:checked + &__decor {
        background: #3A4146;
        &::after {
            $left: 64px - 2 - 28;
            left: $left;
        }
        &_size{
            &_small {
                &::after {
                    $left: 43px - 2 - 20;
                    left: $left;
                }
            }
            &_xLarge {
                &::after {
                    $left: 54px;
                    left: $left;
                }
            }
        }
    }

    input {
        display: none;
    }
}
</style>