<template>
    <div class="tel-list form">
        <div class="tel-list__item" style="height: auto;">
            <div class="tel-list__name">
                {{ timer }}
                <template>
                    {{ value.contact.name }} 
                </template> 
                <button class="button button_type_link button_type_static" v-if="value.contact.company" @click="handleClickToName(value.contact)" style="display: inline-block;">
                    {{ value.contact.name }}
                    <svg aria-hidden="true" width="15" height="15" :style="{marginLeft: '5px'}">
                        <use href="#icon-link"></use>
                    </svg>
                    <template v-if="value.contact.company  &&  value.contact.company.company_name">
                            {{ value.contact.company.company_name }}
                    </template>
                </button>
            </div>
            <div class="tel-list__number" :class="{'tel-list__number-centered': !value.contact.name}">
                {{ value.contact.number }}
                <div class="tel-list__buttons">
                    <button class="button button_with_icon button_type_icon" 
                        :class="{
                            'button_color_transp-yellow': showTransfer,
                            'button_color_transp-gray': !showTransfer
                    }"
                        :style="{marginRight: '24px'}"
                        @click="showTransfer = !showTransfer"
                    >
                        <svg aria-hidden="true" width="9" height="16">
                            <use href="#icon-call-transfer"></use>
                        </svg>
                    </button>

                    <button class="button button_with_icon button_type_icon" 
                        :class="{
                            'button_color_transp-black': !isMute,
                            'button_color_transp-gray': isMute
                    }"
                        :style="{marginRight: '24px'}"
                        @click="handleMuted"
                    >
                        <svg aria-hidden="true" width="9" height="16">
                            <use href="#icon-microphone"></use>
                        </svg>
                    </button>
                    <button class="tel-list__button-red button button_with_icon button_type_icon button_color_transp-black" @click="$emit('action', {item:value, action: 'inchangup'})">
                        <svg aria-hidden="true" width="30" height="30">
                            <use href="#icon-phone-denay-circle"></use>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="tel-list__number" :style="{marginTop: '20px', alignItems: 'center'}" v-if="showTransfer">
                <v-custom-input class="size_medium width__full"
                    placeholder="введите номер"
                    v-model="phoneNumber"
                />
                <button class="tel-wind__call button button_with_icon button_type_icon button_color_transp-black" 
                        @click="handleTransfer"
                        :disabled="value.contact.transferring"
                        :style="{opacity: value.contact.transferring ? '.3' : '1'}"
                        type="submit"
                    >
                        <svg aria-hidden="true" width="30" height="30">   
                            <use href="#icon-transfer-circle"></use> 
                        </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import vCustomInput from '@components/global/CustomInput'
import vButton from '@components/global/vButton'
import api from '@api'
export default {
    components: {
        vCustomInput,
        vButton
    },
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        currentNumber: {
            type: String,
            default: ''
        },
        timer: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isMute: false,
            showTransfer: false,
            phoneNumber: '',
        }
    },
    mounted() {
        const {id, number } = this.value
        const payload = {
            uuid: id,
            customer: number,
            type: number == this.currentNumber ? 'outgoing' : 'incoming'
        }

        this.send({payload, type: 'answer'})
    },
    beforeDestroy() {
        //this.send({payload, type: 'hangup'})
    },
    methods: {
        send(data) {
            console.log(data)
        },
        handleTransfer() {
            const item = {
                number: this.phoneNumber,
            }
            this.$emit('action', {action: 'transfer', item})
        },
        handleMuted() {
            this.isMute = !this.isMute
            this.$emit('action', {action: 'muteSwitch'})
        },
        handleClickToName(contact) {
            this.getRoute(contact).then(()=>{
                const route = contact.route
                this.$router.push(route)    
            })
            
        },
        getRoute(contact) {
            const payload = {
                customer: contact.number
            }
            return api.chat.telephonySearch(payload,'','post').then(res=>{
                const {type} = res.response
                if(type == 'driver') { 
                    contact.route =  this.getRouteOnDriver(res.response)
                } else { 
                   contact.route = this.getRouteOnCandidate(res.response)
                }
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
            
        },
        
        getRouteOnDriver(driver) {
            const {park_id,id} = driver.driver_info
            return {name: "DriverEdit", query: { driver_id:id, park_id}}
        },
        getRouteOnCandidate(driver) {
            const {id} = driver.candidate_info
            return {name: 'CandidateEdit', query: {id}}    
        },
    }
}
</script>

<style lang="scss">

</style>
