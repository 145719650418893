<template>
    <v-side-swipe-bar :show="$store.getters.barTrans.value" @hide="$emit('hide')" :outsideDisabed="true">
        <div class="qpay__header 4" slot="header">
            <p> Транзакции </p>
        </div>
        <div slot="body"> 
            <div class="form__row" v-if="!selected">
                <v-driver-search
                    class="size_large"
                    icon="#icon-search"
                    placeholder="Введите"
                    label="Поиск водителя"
                    buttonIcon="icon-loading"
                    :buttonIconClass="{'await':true}"
                    @select="HandlerSelectDriver($event)"
                />
            </div> 
             <transition name="fade" mode="out-in">
                <div v-if="!isloading && selectedDriverDetail" class="form">
                    <div class="form__row">
                        <p class="qpay__title">
                            Информация о водители
                        </p> 
                        
                        <ul class="qpay__list">
                            <li class="qpay__item qpay__item_color_purpl">
                                <router-link :to="{name: 'DriverEdit', query: { driver_id:selectedDriverDetail.id, park_id: selectedDriverDetail.park_id }}">
                                    ФИО: {{ selectedDriverDetail.full_name}}
                                </router-link>
                            </li>
                            <li class="qpay__item">
                                <p>
                                    Телефон: {{ selectedDriverDetail.phone}}
                                </p>
                            </li>
                            <li class="qpay__item">
                                <p>
                                   <strong>
                                        Баланс: 
                                        <span :class="{
                                                    'qpay__span_color_red': +selectedDriverDetail.balance.replace(' ','') <=0,
                                                    'qpay__span_color_green': +selectedDriverDetail.balance.replace(' ','') >=0
                                                }">
                                            {{ selectedDriverDetail.balance}}
                                        </span>
                                   </strong>
                                </p>
                            </li>
                            <li class="qpay__item">
                                <p>
                                    Город: {{ selectedDriverDetail.city}}
                                </p>
                            </li>
                            <li class="qpay__item">
                                <p>
                                    Условие работы: {{ selectedDriverDetail.work_rule}}
                                </p>
                            </li>
                            <li class="qpay__item">
                                <p>
                                    Позывной: {{ selectedDriverDetail.call_sign}}
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="form__row">
                        <p class="qpay__title">
                            Создание транзакции
                        </p>
                    </div>
                    <div class="form__row">
                        <v-custom-input
                            class="size_large"
                            placeholder="Сумма"
                            label="Сумма"
                            v-model="amount"
                            :error="error? error.amount : ''"
                        />
                    </div>
                    <div class="form__row">
                        <v-custom-area
                            class="size_large"
                            placeholder="Краткое описание операции"
                            label="Описание"
                            v-model="description"
                            :error="error? error.description : ''"
                        />
                    </div>
                    <div class="form__row">
                        <v-custom-select
                            label="Категория"
                            class="size_large"
                            
                            :error="error?error.category_list : ''"
                            :list="category_list"
                            v-model="category"
                        />
                    </div>
                    <div class="form__row">
                        <v-custom-select
                            label="Тип счёта"
                            class="size_large"  
                            :error="error?error.type_account : ''"
                            :list="type_account_list"
                            v-model="type_account"
                        />
                    </div>
                    <div class="form__row">
                        <v-switcher
                            v-model="switcher"
                            @switch="()=>{this.switcher = !this.switcher}"
                        >
                            <span slot="true">Списание</span>
                            <span slot="false">Пополнение</span>
                        </v-switcher>
                    </div>
                    <div class="form__row form__row_type_bottom">
                        <v-button @click="HandleClick"  :disabled="isApiSend" class="qpay__btn button button_size_large button_color_black" type="submit">
                            Совершить транзакцию
                        </v-button>
                    </div>
                </div>
            </transition>
            <transition name="fade" mode="out-in">
                <v-preloader size="small"  v-if="isloading && selectedDriverDetail"/>
            </transition>
        </div>
    </v-side-swipe-bar>
</template>

<script>

import api from '@api'
import vSideSwipeBar from '@components/global/SideSwipeBar'
import vCustomInput from '@components/global/CustomInput'
import vCustomArea from '@components/global/CustomArea'
import vSwitcher from '@components/global/CustomSwitcher'
import vCustomAutocomplete from '@components/global/CustomAutocomplete'
import vCustomSelect from '@components/global/CustomSelect'
import VPreloader from '@components/global/Preloader'
import vButton from '@components/global/vButton';
import vDriverSearch from './DriverSearch.vue'
import { mapState } from 'vuex'
import errors from '@mixin/errors'
import { mapActions } from 'vuex'


export default {
    mixins:[errors],
    props: {
        selected: {
            type: [Object, Boolean],
            default: () => {}
        }
    },
    components: {
        vSideSwipeBar,
        vCustomInput,
        vCustomAutocomplete,
        vSwitcher,
        vCustomArea,
        vCustomSelect,
        vDriverSearch,
        VPreloader,
        vButton
    },
    data() {
        return {
            switcher: false,
            inputDelay: 500,
            category_list: null,
            category: 354, // defoult id
            type_account: 'main',
            timer: {},
            driversList: [],
            isSearchLoadding: null,
            selectedDriverDetail: null,
            error: null,
            search: {
                id: null,
                text: ''
            },
            amount: '',
            description: '',
            number: '',
            isloading: true,
            isApiSend: false
        }
    },
    watch: {
        selected(newValue, oldValue) {
            this.search = newValue
            if(newValue) {
                this.getDetail()
            } else {
                this.selectedDriverDetail = false
            }
        }
    },
    computed: {
        searchLimit() {
            const limit = 5 - this.search.text.length
            if(limit > 0) {
                return 'еще '+limit+' символов'
            }
            return null
        },
        ...mapState({
            type_account_list: state => state.references.type_account_list
        })
    },
    mounted() {
        if(this.selected) {
            const action = this.selected.action
            this.search = this.selected
            this.switcher = action != 'plus'
            this.getDetail()
        }
    },

    methods: {
        ...mapActions([
            'SET_BAR'
        ]),
        HandlerSelectDriver(item) {
            this.search = item;
            if(item.id) this.getDetail()
        },
        HandleClick() {
            this.error = {}
            
            const payload = {
                "driver_id": this.selectedDriverDetail.id,
                "park_id": this.selectedDriverDetail.park_id,
                "type": this.switcher? 'minus' : 'accrual',
                "amount": this.amount,
                "description": this.description ,
                'category_id': this.category,
                'type_account': this.type_account
            }
            this.apiTransaction(payload)
        },
        getDetail() {
            const park_id = this.search.park_id || this.search.park.id
            const driver_id = this.search.id
            api.drivers.search('',`/${driver_id}/${park_id}?short=true`).then(res=>{
                this.selectedDriverDetail = res
                api.transaction.category({ park_id: res.park_id}).then(res=>{
                    this.category_list = res.response
                    this.isloading = false
                }).catch(error=>{
                    this.$toast.error(error.message || err)
                })
            }).catch(err=>{
                this.$toast.error(err.message || err);
            })
        },
        fillErrors(error) {
            this.error = {}
            error.forEach(err=>{ 
                this.error[err.field] = err.message
            })
        },
        apiTransaction(payload) {
            this.isApiSend = true
            api.transaction.create(payload,'', 'post').then(res=>{
                this.$toast.success(res.response.message)
                this.clear()
                if(this.selected) { 
                    this.SET_BAR({name:'trans', value: false})
                    
                    // const {amount,type} = payload
                    // const balance = type == 2? amount * -1 : amount

                    document.dispatchEvent(new CustomEvent('driver_update'));
                    document.dispatchEvent(new CustomEvent('trans_success'));
                  
                }
            }).catch(err=>{
                if (err.response)  this.fillErrors(err.response)
                if (err.message) this.$toast.error(err.message || err);
                console.error(err)
            }).finally(()=>{
                this.isApiSend = false
            })
        },
        clear() {
            this.error = {},
            this.search =  {
                id: null,
                text: ''
            },
            this.selectedDriverDetail = null
            this.amount = null
            this.switcher = false
            this.description = ''
            
        }
    },

}
</script>

<style lang="scss">

</style>