import Janus from 'janus-gateway'
export default {
    data: function () {
        return {
            helpersCount: 0,
            helpers: []
        }
    },

    methods: {
        addHelper (helperCreated) {
            helperCreated = (typeof helperCreated == "function") ? helperCreated : Janus.noop;
            const helpersCount = this.helpersCount++;
            const helperId = helpersCount;
            const _this = this
            this.helpers[helperId] = {
                id: helperId, localTracks: {}, localVideos: 0, remoteTracks: {}, remoteVideos: 0
            };

            
            this.janus.attach({
                plugin: "janus.plugin.sip",
                opaqueId: this.settings.opaqueId,
                success: function (pluginHandle) {
                    const  username = _this.agentName
                    const  master_id= _this.masterId

                    _this.helpers[helperId].sipcall = pluginHandle;
                    //Janus.log("[Helper #" + helperId + "] Plugin attached! (" + helpers[helperId].sipcall.getPlugin() + ", id=" + helpers[helperId].sipcall.getId() + ")");
                    const message = "[Helper #" + helperId + "] Plugin attached! (" + _this.helpers[helperId].sipcall.getPlugin() + ", id=" + _this.helpers[helperId].sipcall.getId() + ")"
                    // _this.$toast.info(message)
                    console.warn('debug:',message)

                    // TODO Send the "register"
                    _this.helpers[helperId].sipcall.send({
                        message: {
                            request: "register", 
                            type: "helper", 
                            username,	// We use the same username as the master session
                            master_id				// Then we add the ID of the master session, nothing else
                        }
                    });
                }, 
                error: function (error) {
                    const message = "[Helper #" + helperId + "]   -- Error attaching plugin..."+ error
                    // _this.$toast.error(message)
                    console.error('debug:',message,error)
                    //removeHelper(helperId);
                }, 
                consentDialog(on) {
                    console.log('debug: consentDialog', {on});
                },
                iceState: function (state) {
                    const message = "[Helper #" + helperId + "] ICE state changed to " + state
                    // _this.$toast.info(message)
                    console.log('debug:',message, state)
                }, 
                mediaState: function (medium, on, mid) {
                    const message = "[Helper #" + helperId + "] Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")"
                    // _this.$toast.info(message)
                    console.log('debug:',message)
                },
                webrtcState: function (on) {
                    const message = "[Helper #" + helperId + "] Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now"
                    // _this.$toast.info(message)
                    console.log('debug:',message)
                },
                slowLink: function (uplink, lost, mid) {
                    const message = "Janus reports problems " + (uplink ? "sending" : "receiving") + " packets on mid " + mid + " (" + lost + " lost packets)"
                    // _this.$toast.warning(message)
                    console.warn('debug:',message)
                },
                onmessage: function (msg, jsep) {
                    const message = "[Helper #" + helperId + "]  ::: Got a message :::"+ msg
                    // _this.$toast.info(message)
                    console.log('helper:', message, {msg, jsep}, )
                    
                    let error = msg["error"];
                    if (error) {
                        // _this.$toast.error(error);
                        console.error(error);
                        return;
                    }
                    _this.vjanMessage(_this.helpers[helperId].sipcall, {msg, jsep})
                },
                onremotetrack: function (track, mid, on) {
                    const message = "[Helper #" + helperId + "] Remote track (mid=" + mid + ") " + (on ? "added" : "removed") + ":"+ track
                    console.log('debug: onremotetrack',message,track)
                    const helpers = _this.helpers
                    // If we're here, a new track was added
                    if (track.kind === "audio") {
                        // New audio track: create a stream out of it, and use a hidden <audio> element
                        let stream = new MediaStream([track]);
                        helpers[helperId].remoteTracks[mid] = stream;
                        const message2= "[Helper #" + helperId + "] Created remote audio stream:"+ stream
                        console.log('debug: message2',message2, stream)
                        _this.addtoAudioList({stream, mid})
                        _this.$nextTick(() => {
                            const _audio = _this.$el.querySelector('#peervideom'+mid)
                            console.log('debug: _audio',{_audio, stream});
                            Janus.attachMediaStream(_audio, stream);
                        })
                    }
                },
                oncleanup: function () {
                    const message = "[Helper #" + helperId + "]  ::: Got a cleanup notification :::"
                    console.log('debug:',message);
                    const helpers = _this.helpers
                    if (helpers[helperId] && helpers[helperId].sipcall) {
                        delete helpers[helperId].sipcall.callId;
                        delete helpers[helperId].sipcall.doAudio;
                        delete helpers[helperId].sipcall.doVideo;
                    }
                    if (helpers[helperId]) {
                        helpers[helperId].localTracks = {};
                        helpers[helperId].localVideos = 0;
                        helpers[helperId].remoteTracks = {};
                        helpers[helperId].remoteVideos = 0;
                    }
                }
                
            })
            return this.helpers[helperId]
        },
        getLastHelper () {
            return this.helpers[this.helpers.length-1]
        },
        removeHelper (helperId) {
            this.helpers = this.helpers.filter(function (helper) {
                return helper.helperId !== helperId;
            });
        },
    }
}