import request from '../../helpers/request'

const DEFAULT_ENDPOINTS = {
    setNewPassword: '/auth/set-new-password',
    verifyForgotten: '/auth/verify-restore-code',
    forgotten: '/auth/request-restore-code',
    login: '/auth/login'
}

export default {
    forgotten: async(payload,url) => {
        const endpoint = url || DEFAULT_ENDPOINTS.forgotten
        const { data } = await request.post(endpoint, payload)
        return data
    },
    verify: async(payload,url) => {
        const endpoint = url || DEFAULT_ENDPOINTS.verifyForgotten
        const { data } = await request.post(endpoint, payload)
        return data
    },
    setNewPassword: async(payload,url) => {
        const endpoint = url || DEFAULT_ENDPOINTS.setNewPassword
        const { data } = await request.post(endpoint, payload)
        return data
    },

    login: async({ email, password, url }) => {
        const endpoint = url || DEFAULT_ENDPOINTS.login
        const { data } = await request.post(endpoint, { email, password })
        return data
    }
}