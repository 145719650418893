<template>
    <div>
        <v-side-swipe-bar :show="$store.getters.barChat.value" 
            @hide="$emit('hide')" :draggable="true" :outsideDisabed="true" :disabledFooter="!!contact"
            @pin="handlePin($event)"    
        >
            <div class="qpay__header 5" slot="header" >
                <button class="button button_type_icon" v-if="contact" @click = "handleClickBack">
                    <svg width="16" height="16">
                        <use href="#icon-arrow-left-crumbs"></use>
                    </svg>
                </button>
                <p> Чат </p>
            </div>
            <template slot="body" >
                <v-chat-contacts @click="handleClickContact" v-if="!contact" />
                <v-chat-messanger v-else 
                    ref="messanger"
                    v-model="contact" 
                    @chatStatusChange="handleChatStatusChange" 
                    @update="handleChatUpdate($event)"
                    @callendchatmodal = "isEndChat = true"
                    :image="image"/> 
                    
            </template>
            <!-- <div slot="footer" v-if="!contact">
                <v-chat-footer v-model="footerCounter" />
            </div> -->
        </v-side-swipe-bar>
        <v-end-chat-modal v-if="isEndChat" @clickCategory="handleClickCategory"/>
    </div>
</template>

<script>
import vChatContacts from '../../chat/chatContacts';
import vChatFooter from '../../chat/chatFooter';
import vSideSwipeBar from '../../global/SideSwipeBar'
import vChatMessanger from '../../chat/chatMessanger.vue' 
import { mapActions } from 'vuex'
import vEndChatModal from '@components/global/vEndChatModal';

import api from '@api';
export default {
    components: {
        vSideSwipeBar,
        vChatContacts,
        vChatFooter,
        vChatMessanger,
        vEndChatModal
    },
    props: {
      selected: {
        type: [Object, Boolean],
        default: () => {}
      }  
    },
    data() {
        return {
            footerCounter: null,
            contact: null,
            image: null,
            isEndChat: false,
        }
    },
    mounted() {
        this.getFooterCounters().then( res=>{
            this.footerCounter = res
        })
        const _this = this
        document.addEventListener('keydown', e=>{ _this.watchKeyPress(e) })
        if(this.selected) {
            const {contact} = this.selected
            this.image = contact.logo || contact.image
            this.getChatDetail(contact.id).then(response=>{
                this.contact = response
            })
        }
    },
    beforeDestroy() {
        this.RESET_TAB()
    },
    methods: {
        ...mapActions([
            'SET_PIN',
            'RESET_TAB'
        ]),
        watchKeyPress(key) {
            if(key.srcElement.tagName == 'TEXTAREA' || key.srcElement.tagName == 'INPUT') return
            if(key.code == 'Backspace' && this.contact) {
                this.handleClickBack()
            }
        },
        handleClickContact(contact) {
            this.image = contact.logo || contact.image
            const chat_history_id =  contact.chat_history_id
            this.getChatDetail(contact.id).then(response=>{
                this.contact = response
                if(chat_history_id) this.contact.chat_history_id = chat_history_id
            })
        },
        handleChatUpdate(action) {
            this.getChatDetail(this.contact.id).then(response=>{
                if(action == 'assign' ) { 
                    this.contact = null
                    this.$nextTick(() =>{
                        this.contact = response
                    })
                } else {
                    this.contact = null
                }
            })
        },
        handleClickBack() {
            this.contact = null
            this.image = null
        },
        handleChatStatusChange(e) {
            this.getFooterCounters().then( res=>{
                this.footerCounter = res
            })
        },
        getFooterCounters() {
            return api.chat.getCounts().then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        },
        getChatDetail(id) {
            const paylaod = {
                id
            }
            
            return api.chat.getChatDetail(paylaod,'', 'post').then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        },
        handlePin(bool) {
            this.SET_PIN({name:'chat', val:bool})
        },
        handleClickCategory(category) {
            const messanger = this.$refs.messanger
            messanger.handlePopupClose(category.id)
            this.isEndChat = false
        }
    },
}
</script>
