<template>
    <div class="checkbox-cont">
        <label class="checkbox" :class=" {'checkbox__disabled' : disabled}">
            <input type="checkbox"
                :id="id"
                :checked="checked"
                :value="tvalue"
                @change="handlChecked($event)" 
                :name="name"
                :disabled="disabled"
            >
            <span class="checkbox__box" >
                <svg v-if="checked" aria-hidden="true" width="14" height="10">
                    <use href="#icon-checked"></use>
                </svg>
            </span>
                {{label}}
        </label>
    </div>
</template>

<script>
export default {
    name:"vCheckBox",
    props: {
        value: {
            type: [Boolean, Number],
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        id: {
            type: [String, Number],
            default: ''
        },
        tvalue: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            checked: false,
        }
    },
    watch: {
        value(newValue) {
            this.checked = newValue
        },
    },
    mounted() {
        if(this.value) {
            this.checked = this.value
        }
    },
    methods:{
        handlChecked(event) {
            this.checked = event.target.checked
            this.$emit('input', event.target.checked)
        }
    }
}
</script>

<style lang="scss">

.checkbox {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;    
    
    display: flex;
    align-items: center;
    cursor: pointer;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: auto;
    width: auto;

    &-cont {
        display: flex;
        width: 100%;
    }
    &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 20px;

        width: 20px;
        height: 20px;
        margin-right: 12px;
        border: 1px solid #CBCCCF;
        border-radius: 2px;
        color:#fff;
        flex: 0 0 20px;

        svg {
            fill: currentColor;
            flex-shrink: 0;
        }
    }

    &__disabled {
        opacity: .3;
        cursor: not-allowed;
    }

    &.size {
        &__large .checkbox__box{
            width: 30px;
            height: 30px;
        }
    }
    
    input {
        display: none;
    }

    input:checked + &__box {
        background-color: #3A4146;
    }
}

</style>