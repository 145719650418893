import apiClient from './apiClient'
import qs from 'qs'

//const CSRF_TOKEN = localStorage.getItem('token')

const request = apiClient({
    paramsSerializer: params => qs.stringify(params)
})

export default request
