<template>
    <div class="chat-messanger">
        <div class="chat-messanger_cont">
            <div class="chat-messanger__top">
                <div class="chat-messanger__row" v-if="value">
                    <div class="chat-messanger__label">
                        {{ value.client.type }}
                    </div>
                    <div class="chat-messanger__content" v-if="value.client.items.length">
                        <template v-for="item in value.client.items" >
                            <p class="chat-messanger__link" v-if="!item.id || !item.park_id" >
                                {{item.name}}
                            </p>
                            <router-link
                                v-else
                                :key="item.id"
                                :to="{name: 'DriverEdit', query: { driver_id: item.id, park_id: item.park_id }  }" 
                                class="chat-messanger__link" 
                                :class="{ visited: $route.query.driver_id == item.id }"
                            >
                                {{item.name}}
                            </router-link>
                        </template>
                    </div>
                </div>    
                <div class="chat-messanger__row" v-if="value.client.phone">
                    <div class="chat-messanger__label">
                        Номер:
                    </div>
                    <div class="chat-messanger__content">
                        <span class="chat-messanger__name" >
                            {{value.client.phone}}
                        </span>
                    </div>
                </div>
                <div class="chat-messanger__row">
                    <div class="chat-messanger__label" v-if="value.operator"> 
                        Оператор:
                    </div>
                    <div class="chat-messanger__content">
                        <span class="chat-messanger__name">
                            {{ value.operator.name }}
                        </span>
                    </div>
                </div>
                
                <template v-if="!(this.$store.getters.chat_tab == 'history')">
                    <div class="chat-messanger__row" v-if="departments_list">
                        <v-custom-select
                            class="size_medium"
                            v-model="departSelect.id"
                            :list="departments_list"
                            placeholder="Выберите отдел"
                            @input="handleDepartChange()"
                            :disabled="value.is_in_working"
                        />
                    </div>
                    <div class="chat-messanger__row" v-if="is_new_depart">
                        <v-button :size="'full-width'" class="button_size_medium button_color_black" @click="handleTransformClick">Перевести</v-button>
                    </div>
                    <div class="chat-messanger__row" v-if="!value.can_communicate && value.can_assign">
                        <v-button :size="'full-width'" class="button_size_medium button_color_black" :disabled="!value.can_assign" @click="handleClickAppoint">Назначить мне</v-button>
                    </div>
                    <div class="chat-messanger__row chat-messanger__row-inline" v-if="value.can_communicate && !value.can_assign">
                        <v-button :size="'full-width'" class="button_size_medium button_color_white " @click="handleReturnToList">Вернуть в список</v-button>
                        <v-button :size="'full-width'" class="button_size_medium button_color_black " @click="handleComplete">Завершить</v-button>
                    </div>
                </template>
            </div>
            <div class="chat-messanger__messages" ref="messages" 
                @mouseenter="handleMouseenter"
                @mouseleave="handleMouseleave"
                @scroll="handleScrollMessage" 
                :class="{'chat-messanger__messages_type_empty': !messages}
            ">
                <div class="chat-messanger__messages-preloader" v-if="showLoader">
                    <v-preloader v-if="messages && messages.length" class="preloader_type_button" size="half"/>
                </div>

                <template v-if="(!messages || !messages.length )">
                    <p class="chat-messanger__empty-text">Чат с этим пользователем ещё не начат</p>
                </template>
                <template v-else v-for="message in messages">
                    <v-message :message="message" :img="image" :key="message.id" @imgload="handleImgLoad"/>
                </template>
            </div>
            <div class="chat-messanger__form" v-if="value.can_communicate">
                <v-chat-form ref="chatForm" @submit="handleSubmit" :disabled="chatFormDisabled" @fastTogle="handlerFastTogle" :isAttach="value.can_attach_file"/>
            </div>
        </div>
        <v-fast-category v-if="isFastShow" :chat_id="value.id" @phraseClick="handlePhraseClick"/>
    </div>
</template>

<script>
import vButton from '../global/vButton';
import vChatForm from './modules/chatform';
import vCustomSelect from '../global/CustomSelect';
import vMessage from './modules/message.vue'
import VPreloader from '../global/Preloader.vue';
import { mapState, mapActions } from 'vuex'
import api from '@api';
import { eventBus } from '@eventBus'
import vFastCategory from './modules/fastCategory';

export default {
    props: {
        value: false,
        image: ''
    },
    components: {
        vCustomSelect,
        vButton,
        vChatForm,
        vMessage,
        VPreloader,
        vFastCategory,
    },
    data () {
        return {
            category_id:null,
            
            messages: [], // сообщения 
            departSelect: {id: null, text: null}, // выбраный раздел
            first_message_id: null, // оследнее солобщение в массиве сообщений
            chatFormDisabled: false, // флаг доступа к форме отправки сообщений
            showLoader: true, // флаг отобржения loader
            isMessageLoading: false, // флаг загрузки сообщений

            is_new_depart: false, //Флаг определяющий новый отдел
            isUserScroll: false, //флаг сролла пользователем 
            fixedScroll: true, //флаг фиксации на последнем сообщении
            awaitScrollBottom: true, // флаг оджидания прокрутки в низ
            isFastShow: false,

            fastPhareId: null,

        }
    },
    computed:{
        ...mapState({
            departments_list: state => state.chat.departments_list, //список отделов
        }),
    },
    beforeMount() {
        if(!this.departments_list) {
            this.GET_CHAT_DEPARTMENTLIST().catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        }
    },
 
    mounted() {
        this.recycleGetMessage()
        this.departSelect = this.value.department
        eventBus.$on('socketMessage', this.handleNewMessage);
    },
    methods: {
        ...mapActions([
            'GET_CHAT_DEPARTMENTLIST',
        ]),
        handlerFastTogle() {
            this.isFastShow = !this.isFastShow
            const sideBarWrapper = document.querySelector('.sideBar__wrapper')
            const sideBar = document.querySelector('.sideBar')
            sideBarWrapper.style.overflow = this.isFastShow ? 'initial' : ''
            sideBar.style.overflow = this.isFastShow ? 'initial' : ''
        },
        handleDepartChange() {
            this.is_new_depart = true
        },

        // Обработчик отправки сообщения
        handleSubmit(form) {
            const { message, files } = form
            this.submitMessage({message, files}).then(res => {
                if (!this.messages.length) this.messages = []; // Проверка, если массив сообщений пустой, создаем его
                res.response.forEach(message => {
                    this.messages.push(message); // Добавляем полученное сообщение в массив сообщений
                })
                this.$nextTick(() => {
                    this.isUserScroll = false; // Установка флага isUserScroll в false
                    this.scrollToBottom()
                    this.isUserScroll = true; // Установка флага isUserScroll в true
                });
            });
        },
        handlePhraseClick(item) {
            this.$refs.chatForm.setFastPhrase(item.name)
            this.fastPhareId = item.id
        },
        

        // Обработчик события прокрутки контейнера сообщений
        handleScrollMessage($event) {
            const messagesContainer = this.$refs.messages;
            
            if (this.isUserScroll) {
                this.fixedScroll = false; // Если прокрутку инициировал пользователь, устанавливаем флаг fixedScroll в false
            }

            if (!this.isMessageLoading && messagesContainer.scrollTop <= 100) {
                // Если прокрутка достигла верхней границы контейнера

                const first_message_id = this.getFirstMessage() // получаем id первого сообщения
                if(this.isLastMessage(first_message_id)) return
                
                // Получаем дополнительные сообщения
                this.first_message_id = first_message_id; // Запоминаем первое (старое) сообщение из сообщений
                
                this.getMessages(this.value.id, first_message_id).then(res => {
                    this.concatMessages(res.response); // Объединяем полученные сообщения с уже существующими
                    this.$nextTick(()=>{
                        //if(!this.fixedScroll) return
                        const messagesContainer = this.$refs.messages;
                        if(!messagesContainer) return 

                        const lastmessage = messagesContainer.querySelector('#message-' + first_message_id);
                        lastmessage.scrollIntoView(); // Прокручиваем контейнер к последнему добавленному сообщению
                        // console.log({lastmessage, first_message_id})
                    })
                });
            }

            const isScrolledToBottom = messagesContainer.scrollTop + messagesContainer.clientHeight > (messagesContainer.scrollHeight - 5);
            if (isScrolledToBottom) {
                this.fixedScroll = true; // Если прокрутка достигла нижней границы контейнера, устанавливаем флаг fixedScroll в true
                if(this.awaitScrollBottom) {
                    this.awaitScrollBottom = false
                    this.isUserScroll = true; // Устанавливаем флаг isUserScroll в true
                }
            }
        },

        handleImgLoad(data) {
            // console.log('fixedScroll', this.fixedScroll);
            
                // this.isUserScroll = false; // Установка флага isUserScroll в false
                // this.scrollToBottom()
                // this.isUserScroll = true; // Установка флага isUserScroll в true
            
        },
        scrollToBottom() {
            const messagesContainer = this.$refs.messages;
            if(!messagesContainer) return
            messagesContainer.scrollTo({top:messagesContainer.scrollHeight, behavior: 'smooth'}) // Прокручиваем контейнер сообщений вниз
        },

        //Клик по кнопке "назначить мне"
        handleClickAppoint() {
            this.chatUpdate('assign').then(res=>{
                eventBus.$emit('socketEmit', {method: 'send', data:res})
                this.$emit('chatStatusChange', 'assign')
            })
        },
        
        //Клик по кнопке "Вернуть в список"
        handleReturnToList() {
            this.chatUpdate('return').then(() => {
                this.$emit('chatStatusChange', 'return')
            })
        },
        handleTransformClick() {
            this.chatUpdate('change-department').then(() => {
                this.$emit('chatStatusChange', 'change-department')
                //eventBus.$emit('socketEmit', {method: 'send', data:res})
            })
        },

        //Клик по кнопке Завершить
        handleComplete() {
            this.value.can_choose_category? this.$emit('callendchatmodal', 'end'): this.handlePopupClose()
        },
        handlePopupClose(category_id) {
            this.chatUpdate('end',category_id).then(() => {
                this.$emit('chatStatusChange', 'end')
            })
        },

        handleMouseenter() {
            const element = this.$refs.messages
            const hasScroll = element.scrollHeight > element.clientHeight;
            if (hasScroll) {
                // element.style.marginRight = '-10px'
                // element.style.paddingRight = '3px'
            }
        },
        handleMouseleave() {
            const element = this.$refs.messages
            element.style.marginRight = null
            element.style.paddingRight = null
        },

        //Рекурсивная подгрузка сообщений, пока не заполнено все пространство сообщений или не загружено последнее сообщение
        recycleGetMessage() {
            const first_message_id = this.getFirstMessage()
            if(this.isLastMessage(first_message_id)) return

            // this.first_message_id = first_message_id
            this.getMessages(this.value.id, first_message_id).then(res => {
                if (!this.messages) this.messages = []; // Проверка, если массив сообщений пустой, создаем его
                this.concatMessages(res.response); // Объединяем полученные сообщения с уже существующими
                this.$nextTick(() => {
                    const messagesContainer = this.$refs.messages;
                    
                    if(!messagesContainer || !this.messages.length) return
                    if (messagesContainer.offsetHeight == messagesContainer.scrollHeight) {
                        this.recycleGetMessage();
                    } else {
                        this.isUserScroll = false; // Установка флага isUserScroll в false
                        messagesContainer.scrollTop = messagesContainer.scrollHeight; // Прокручиваем контейнер сообщений вниз
                        this.isUserScroll = true; // Установка флага isUserScroll в true
                    }
                });
            });
        },

        /**
         * Обновляет чат с заданным действием.
         * @param {string} action - Действие, которое нужно выполнить (assign, return, end).
         * @returns {Promise} - Промис, который разрешается с ответом от сервера.
        */
        chatUpdate(action,category_id) {
            const id = this.value.id; // Идентификатор чата
            const department_id = action == 'change-department' ? this.departSelect.id : ''
            let payload = { id, action, department_id }; // Подготовка данных для запроса
            if(category_id && action == 'end') payload.category_id = category_id
            return api.chat.update(payload, '', 'put').then(res => {
                this.$toast.success(res.response.message); // Отображение сообщения об успешном обновлении
                this.getMessages(this.value.id).then(res => {
                    this.messages = res.response; // Получаем сообщения и обновляем массив messages
                });
                this.$emit('update', payload.action); // Генерация события update с передачей действия
                return res.response; // Возвращение ответа от сервера
            }).catch(error => {
                this.$toast.error(error.message); // Отображение ошибки
                console.error(error.message)
            }).finally(e => {
                this.isloading = false; // Установка флага isloading в false
            });
        },

        /**
         * Получает сообщения из чата.
         * @param {string} id - Идентификатор чата.
         * @param {string} first_message_id - Идентификатор первого сообщения.
         * @returns {Promise} - Промис, который разрешается с ответом сервера или отклоняется с ошибкой.
        */
        getMessages(id, first_message_id) {
            const payload = { id, first_message_id }; // Подготовка данных для запроса
            
            const chat_tab = this.$store.getters.chat_tab // Получение выбранной вкладки
            if(chat_tab == 'history') return this.getHistoryMessages('','/'+this.value.chat_history_id) // Если выбрана вкладка "История"

            this.isMessageLoading = true; // Установка флага isMessageLoading в true для отображения загрузки
            
            return api.chat.getMessages(payload, '', 'post').then(response => {
                return response;
            }).catch(error => {
                this.$toast.error(error.message);
            }).finally(e => {
                this.isMessageLoading = false;
            });
        },
        getHistoryMessages(paylaod,param) {
            return api.chat.getHistyoryItems(paylaod,param).then(res=>{
                return res
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isMessageLoading = false;
            })
        },

        /**
         * Отправляет сообщение в чат.
         * @param {string} message - Текст сообщения.
         * @returns {Promise} - Промис, который разрешается с ответом сервера или отклоняется с ошибкой.
        */
        submitMessage(data) {
            this.chatFormDisabled = true; // Установка флага chatFormDisabled в true для отображения загрузки
            const chat_id = this.value.id; // Идентификатор чата
            const quick_phrase_id = this.fastPhareId || ''; // Идентификатор последней быстрой фразы
            const {message,files} = data
            
            const formData = new FormData();
            
            if(files && files.length) { 
                formData.append('type', 'media');    
            
                for (let i = 0; i < files.length; i++) {
                    formData.append('files[]', files[i]);
                }
            }
            formData.append('chat_id', chat_id);
            formData.append('message', message || '');
            formData.append('quick_phrase_id', quick_phrase_id);
            

            this.fastPhareId = null; // Очистка переменной fastPhareId
            return api.chat.submit(formData, '', 'post').then(response => {
                return response; // Возвращение ответа сервера
            }).catch(error => {
                this.$toast.error(error.message); // Отображение ошибки с помощью toast-уведомления
            }).finally(e => {
                this.chatFormDisabled = false; // Установка флага chatFormDisabled в false после завершения операции
            });
        },

        /**
         * Обрабатывает новое сообщение, полученное через сокет.
         * @param {Object} messageData - Данные нового сообщения.
        */
        handleNewMessage(messageData) {
            if(this.value.id != messageData.chat_id) return
            const message = messageData.message_item
            message.fromSocket = true
            this.messages.push(message); // Добавление нового сообщения в массив messages
            const _this = this
            this.$nextTick(() => {
                // Скролл к новому сообщению, если пользователь не прокручивал вверх
                _this.isUserScroll = false; // Установка флага isUserScroll в false
                _this.awaitScrollBottom = true; // Установка флага awaitScrollBottom в true
                if (!_this.fixedScroll) return; // Если fixedScroll равен false, выходим из метода
                const messagesContainer = _this.$refs.messages; // Получаем ссылку на контейнер сообщений
                if(!messagesContainer) return // todo: fix вызывается 2 раза если приходит сообщение если контакт из мессенджера определить fix вызывается 2 раза если приходит сообщение
                messagesContainer.scrollTo({ top: messagesContainer.scrollHeight, behavior: 'smooth' });// Прокручиваем контейнер к последнему сообщению с плавной анимацией
            });
        },
        /**
         * Объединяет новые сообщения с уже существующими.
         * @param {Array} message - Новые сообщения.
        */
        concatMessages(messages) {
            //записывать id и искать дубли, если они есть - не записывать их в сообщение
            const temp = []
            messages.forEach(message => {
                const isInMessage = !!this.getitemById(this.messages, message.id)
                if(isInMessage) return; // Если уже существует, пропускаем
                temp.push(message)
            });
            //this.messages = messages.concat(this.messages); // Добавление новых сообщений в начало массива messages
            this.messages = temp.concat(this.messages);
        },
        getitemById(mass,id) {
            return mass.find(item => item.id == id)
        },
        // Получение первого сообщения
        getFirstMessage() {
            return this.messages.length ? this.messages[0].id : null; // Получаем ID первого сообщения в массиве messages
        },
        /**
         * Проверяет, является ли текущее сообщение последним загруженным сообщением.
         * @returns {boolean} true, если текущее сообщение является последним загруженным сообщением, в противном случае - false.
        */
        isLastMessage(first_message_id) {
            if (this.first_message_id && this.first_message_id == first_message_id) {
                // Проверяем, если first_message_id уже существует и совпадает с текущим first_message_id
                this.showLoader = false; // Устанавливаем флаг showLoader в false
                return true; // Возвращаем true, так как текущее сообщение является последним загруженным сообщением
            }
            this.first_message_id = first_message_id
            return false; // Возвращаем false, так как текущее сообщение не является последним загруженным сообщением
        },
    }
}
</script>

<style lang="scss">
@import '../../../../gulp-src/app/scss/_variables';
@import '../../../../gulp-src/app/scss/_mixins';
    .chat-messanger {
        display: flex;
        flex-direction: column;
        height: 100%;
        &_cont {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        &__form {
            padding-top: 16px;
            border-top: 1px solid #CBCCCF;
        }
        &__empty-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #919197;
        }
        &__messages {
            flex: 1 1 100%;
            overflow: auto;
            margin-right: -10px;
            padding-right: 10px;
            margin-bottom: 16px;
            max-width: 100% ;
            @include scrollbar($thumb-color:#3A4146, $track-color:#CBCCCF);

            &:hover::-webkit-scrollbar {
                width: 11px;
            }

            &_type {
                &_empty {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        &__messages-preloader {
            .preloader {
                max-width: 200px;
                margin: auto;
            }
        }

        &__top {
            border-bottom: 1px solid #CBCCCF;
            margin-bottom: 16px;
            flex: 0 0 auto;
            max-width: 100%;
        }
        &__row {
            margin-bottom: 12px;
            display: flex;
            .custom-select {
                width: 100%;
            }
        }

        &__row-inline {
            display: flex;
        }

        &__label {
            font-size: 14px;
            line-height: 20px;
            color: #919197;
            padding-right: 15px;
            min-width: 100px;
            svg { 
                fill: #3A4146;
            }
        }
        
        &__link {
            color: #6362E5;
            white-space: pre-wrap;
            display: block;
            // text-overflow: ellipsis;
            // overflow: hidden;
            max-width: 100%;
            // display: block;

            font-size: 14px;
            line-height: 20px;

            &:visited {
                color: purple; /* Дефолтный цвет для посещенных ссылок */
            }
        }

        &__name {
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 500;
            overflow: hidden;
            max-width: 100%;
            display: inline-block;
            vertical-align: middle;
            color: #0A0A17;
        }
    }
</style>