import api from '@api'
import { eventBus } from '@eventBus'
export default {
    data() {
        return {
            call_id: null,
        }
    },
    methods: {
        statisticSend(data) {
            console.log('stat: statisticSend', data)
            if( data.event == "registered") return 
            const action = data.event
            switch (action) {
                case 'calling':
                    this.sendOnTtel(data)
                break
                case 'incomingcall': 
                    this.sendOnTtel(data)
                break
                default :
                    this.updateOnTel(data)
                break
            }
        },
        statisticMakeCall(data) {
           const payload = {
               customer: data.phoneNumber,
               call_id: data.msg.call_id
           }
           this.call_id = data.msg.call_id
           this.send(payload)
        },
        statisticHangup(data) {
            // console.log('stat: statisticHangup', data)
            let payload = {}
            if(this.call_id && this.call_id == data.msg.call_id) {
                payload = {
                    call_id: this.call_id,
                    event: data.msg.result.reason,    
                }
                this.send(payload, 'put').then(res=>{
                    if(res.response) eventBus.$emit('endCall', res.response);
                })
                this.call_id = null
            }
        },
        sendOnTtel(data) {
          const event = data.event
          const call_id  = data.msg.call_id
          const customer = data.msg.result.callee || data.phoneNumber
          console.log('stat: sendOnTtel',{event, call_id, customer})
          const payload = {
              event,
              call_id: this.removeSettings(call_id),
              customer: this.removeSettings(customer)
          }
          this.send(payload);
        },
        updateOnTel(data) {
            const payload = {
                event: data.event,
                call_id: this.removeSettings(data.msg.call_id),
            }
            console.log('stat: updateOnTel', payload);
            this.send(payload,'put');
        },
        removeSettings(str) {
            return str ? str.split('@')[0].replace('sip:','') : ''
        },
        send(payload, method) {
            console.log('stat: send', payload, method);
            // return api.tel.telephonys(payload, '', method || 'post').then(response=>{
            //     return response
            // }).catch(error=>{
            //     this.$toast.error(error.message)
            //     console.log(error)
            // })
        }        
    }
}