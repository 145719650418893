import req from './request'

const DEFAULT_ENDPOINTS = {
    create: '/payments/base',
    daily: '/payments/base/daily', //отображает итнфо о платежах в шапке
    provider: '/payments/base/retrieve',
    references: '/payments/base/reference',
    provider_balance: '/provider/balance',
    summary: '/payments/base/summary',
    totalCount: '/payments/base/total-count',
    config: '/payments/base/list-config',
    queue: '/payments/system/queue',
    retrieveByService: '/payments/base/retrieve-by-service',
    retrieveTemplate: '/payments/base/retrieve-template',

    paymentsSystemWithdrawReference: '/payments/system/withdraw/reference',
    paymentsSystemWithdraw:'/payments/system/withdraw',
    paymentsfrozenFunds: '/payments/frozen-funds'

}

export default {
    
    config(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.config, param)
    },
    retrieveByService(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.retrieveByService, param)
    },
    totalCount(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.totalCount, param)
    },
    summary(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.summary, param)
    },
    balance(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.provider_balance, param)
    },
    create(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.create, param)
    },
    references(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.references, param)
    },
    daily(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.daily, param)
    },
    provider(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.provider, param)
    },

    queue(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.queue, param)
    },
    retrieveTemplate(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.retrieveTemplate, param)
    },
    paymentsSystemWithdrawReference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.paymentsSystemWithdrawReference, param)
    },
    paymentsSystemWithdraw(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.paymentsSystemWithdraw, param)
    },
    paymentsfrozenFunds(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.paymentsfrozenFunds, param)
    }
}

