<template>
    <div class="tel" v-if="telProp.JanysReady">
        <button class="tel-button" v-if="!showWind" @click="showWind = true"
            :class="{'tel-button_active': telProp.isCall || telProp.calling }"
        >
            <div class="tel-button__counter" v-if="telProp.callList.length && !telProp.telInput.timer">
               {{ telProp.callList.length }}
            </div>
            <div class="tel-button__timer" v-if="telProp.telInput.timer">{{ telProp.telInput.timer }}</div>
            <svg aria-hidden="true" width="24" height="24">
                <use href="#icon-phone-2"></use>
            </svg>
        </button>
        
        <v-tel-wind 
            @action="handleAction"
            @close="showWind = false" 
            :agentName="agentName" 
            v-model="telProp" 
            ref ="telWind" v-else 
            @dtmf="handleDTMF"
        />
        <template v-for="audio in audioList">
            <audio :id="'peervideom' + audio.mid" autoplay playsinline />
        </template>
    </div>
</template>

<script>
    import { eventBus } from '@eventBus'
    import vTelWind from './modules/TelWind'
    import mixJanus from './mixin/vJan'
    import vPreloader from '@components/global/Preloader'
    import telStatistic from './mixin/telStatistic'
    export default {
        name: 'Tel',
        mixins: [mixJanus, telStatistic],
        components: {
            vTelWind,
            vPreloader,
        },
        data() {
            return {
                showWind: false,
                isJanus: false,
                timer: false,
                page_id: null,
            }
        },
        mounted() {
            
            this.page_id = new Date().getTime()
            this.timer = setTimeout(() => {
                this.isJanus = true
                console.log('tabstatus:','запускаем janus');
                this.vJanInit()
                eventBus.$on('globalMakeCall', this.handleMakeCall);
            }, 500)
            const channel = new BroadcastChannel('myChannel');
            channel.postMessage({ type: 'checkTabs' });
            channel.onmessage = (event) => {
                const data = event.data;
                console.log('tabstatus:', data.type);
                if (data.type === 'checkTabs') {
                    // Отправляем ответ в канал  и статус janus 
                    channel.postMessage({ type: 'checkTabsResponse',  isJanus:this.isJanus });
                }

                if (data.type === 'checkTabsResponse') {
                    // Если другая вкладка уже ответила, значит, есть другие открытые вкладки
                    console.log('tabstatus:','Есть другие открытые вкладки', data.isJanus);
                    if(data.isJanus) {
                        console.log('tabstatus:','и janus запущен');
                        clearTimeout(this.timer)
                    }
                }

                if (data.type === 'tabClose') {
                    console.log('tabstatus: какая-то вкладка закрылась', data)
                    if(data.isJanus) {
                        console.log('tabstatus:','и на той вкладке был  запущен janus');
                        channel.postMessage({ type: 'getOlderPage'});
                        this.timer = setTimeout(() => {
                            console.log('tabstatus:','Больше вкладок нету, запускаем janus');
                            this.vJanInit()
                        },500)
                    }
                }

                if (data.type === 'getOlderPage') {
                    console.log('tabstatus:','получили старую вкладку', data)
                    channel.postMessage({ type: 'pageTaime',  page_id: this.page_id });
                }
                if (data.type === 'pageTaime') {
                    const page_id = data.page_id
                    console.log('tabstatus:','поиск самой старшей вкладки', data)
                    if(this.timer ) clearTimeout(this.timer)
                    if(this.page_id <=page_id) {
                        console.log('tabstatus:','Это самая старая вкладка, запуск janus', data)
                        this.vJanInit()
                        this.isJanus = true
                    }
                }
            };
            const _this = this
            window.addEventListener('unload', function (event) {
                channel.postMessage({ type: 'tabClose', isJanus: _this.isJanus });
            });
        },
        methods: {
            handleMakeCall(e) {
                const {phoneNumber} = e
                this.telProp.telInput.phoneNumber = phoneNumber
                this.handleAction({phoneNumber, action:'call'})
            },
            handleAction(event) {
                const action = event.action
                switch(action) {
                    case 'call':
                        this.vJanusMakeCall(event.phoneNumber)
                        break;
                    case 'accept':
                        this.vJanusAcceptIncoming(event.item)
                        break;
                    case 'inchangup':
                        this.vJanusHangupCall(event.item)
                        break;
                    // case 'hangup':
                    //     this.vjanusHangup(event.item)
                    //     break;
                    case 'transfer':
                        this.vJanusTransferCall(event.item)
                        break;
                    case 'muteSwitch': 
                        this.vJanusMuteAudio()
                        break;
                }
            },
            handleDTMF(event) {
              this.vJanusDTMF(event)
            }
        }
    }
</script>

<style lang="scss">
    .tel {
        position: fixed;
        bottom: 20px;
        left: 71px;
        z-index: 1000;
        @media (min-width: 1500px) {
            left: 103px;
        }
    }
    .tel-button {
        
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        background-color: #fff;
        box-shadow: 0px 4px 12px rgba(58, 65, 70, 0.12), 0px 2px 4px rgba(58, 65, 70, 0.12);
        border-radius: 50%;

        &__counter,&__timer { 
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 30px;
            min-width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #E94A4A;
        }

        &__timer { 
            width: auto;
            right: inherit;
            left: 100%;
            margin-left: -25px;
            padding-left: 8px;
            padding-right: 8px;
        }

        &_active {
            background-color: #30C66C;
            color: #fff;
            
        }
        svg {
            fill: currentColor;
        }
    }
</style>