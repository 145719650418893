<template>
    <v-side-swipe-bar :show="$store.getters.barCompany.value" @hide="$emit('hide')" :outsideDisabed="true">
         <div class="qpay__header 1" slot="header">
            <p> Поиск компании </p>
        </div>
        <div slot="body">
            <div class="form__row">
                <v-custom-input 
                    v-model="search"
                    class="size_large"
                    placeholder="Введите название компании"
                    label="Поиск компании"
                    :icon="'#icon-search'"
                    :isload="isloading"
                    @keyup="handleInput"
                />
            </div>
            <template v-if="filteretList.length && filteretList || list">
                
                <div class="form__row" v-for="item in filteretList.length && filteretList || list" :key="item.id">
                    <button 
                        @click="HandleClick(item)"
                        class="loop_btn button button_text_left button_color_black  button_with_icon"
                        :class="{
                            'button_type_disabled': item.isDisabled
                        }"
                        :disabled="item.isDisabled"
                    >
                        <span>
                            {{ item.name }}
                        </span>
                    </button>
                </div>


            </template>
        </div>
    </v-side-swipe-bar>
</template>

<script>
import vSideSwipeBar from '../../global/SideSwipeBar'
import vCustomInput from '../../global/CustomInput.vue'
import api from '@api'
import { eventBus } from '@eventBus'

export default {
       components: {
        vCustomInput,
        vSideSwipeBar
    },
    data() {
        return {
            list: false,
            filteretList: [],
            search: '',
            isloading: false
        }
    },
    mounted () {
        this.getCompany().then(response=>{
            this.list = response
        })
    },
    methods: {
        HandleClick(item) {
            eventBus.$emit('changeCompany', item);
        },
        handleInput() {
            this.filteretList = this.getfilterLsit()
            if(!this.filteretList.length && this.search) {
                this.filteretList = [{name: 'Ничего нету', id: null, isDisabled: true}]
            }
        },
        getCompany() {
            this.isloading = false
            return api.profile.company().then(res => {
                return res.response
            }).catch(error=>{
                if(error.message) this.$toast.error(error.message)
                console.error(error)
            }).finally(e=>{
                this.isloading = false
            })
        },
        getfilterLsit() {
            return this.list.filter(item => {
                return item.company_id === this.search || this.compareStrings(item.name, this.search)
            });
        }, 
        compareStrings(mainString, searchString) {
            mainString = mainString.toLowerCase();
            searchString = searchString.toLowerCase();
            return mainString.includes(searchString);
        }
    },
}
</script>

<style lang="scss">
.loop_btn {
    margin-right: 0;
    width: 100%;
    height: auto;
    min-height: 48px;
    span {
        display: block;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
}
</style>