import antifraud from './modules/antifraud'
import auth from './auth/modules/auth'
import bannerImage from './modules/bannerImage'
import candidats from './modules/candidats'
import card from './modules/card'
import carReferences from './modules/carReferences'
import chart from './modules/chart'
import chat from './modules/chat'
import communications from './modules/communications'
import company from './modules/company'
import driverReferences from './modules/driverReferences'
import drivers from './modules/drivers'
import other from './modules/other'
import payments from './modules/payments'
import permission from './modules/permission'
import profile from './modules/profile'
import promotions from './modules/promotions'
import references from './modules/references'
import referral from './modules/referrals'
import teams from './modules/teams'
import tel from './modules/tel'
import terminals from './modules/terminals'
import transaction from './modules/transaction'
import vexport from './modules/export'
import dumps from './modules/dumps'

export default {
    antifraud,
    auth,
    bannerImage,
    candidats,
    card,
    carReferences,
    chart,
    chat,
    communications,
    company,
    driverReferences,
    drivers,
    other,
    payments,
    permission,
    profile,
    promotions,
    references,
    referral,
    teams,
    tel,
    terminals,
    transaction,
    vexport,
    dumps
}