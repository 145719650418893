<template>
    <div class="tel-wind form">
        <div class="tel-wind__header">
            <p class="tel-wind__title">
                Телефония
                <span>Агент {{agentName}}</span>
            </p>
            <button class="button button_with_icon button_type_icon button_color_transp-black" @click="$emit('close')">
                <svg aria-hidden="true" width="20" height="20">
                    <use href="#icon-close"></use>
                </svg>
            </button>
        </div>
        <div class="tel-wind__row">
            <v-tel-input v-model="value.telInput" :disabled="!value.isCall && value.calling" :isCall="value.isCall" @action="$emit('action', $event)" @input="handlInput"/>
        </div>
        <transition name="grow-in-out" >
            <div class="tel-wind__row" :key="'title'" v-if="value.currentCall">
                <p class="tel-wind__subtitle">
                    Текущий звонок 
                </p>
            </div>
        </transition>
        <transition name="grow-in-out" >
            <div class="tel-wind__row" :key="'current'" v-if="value.currentCall">
                <v-current-call v-model="value.currentCall" :timer="value.telInput.timer" :disabled="value.isCall || value.calling" :currentNumber="value.telInput.phoneNumber"  @action="$emit('action', $event)"/>
            </div>
        </transition>
        <div class="tel-wind__row">
            <p class="tel-wind__subtitle">
                Входящие
            </p>
        </div>
        <div class="tel-wind__row">
            <v-incoming-callList v-model="value.callList" :disabled="value.isCall || value.calling" @action="$emit('action', $event)"/>
        </div>
    </div>
</template>

<script>

import vIncomingCallList from './IncCallList'
import vButton from '@components/global/vButton'
import vPreloader from '@components/global/Preloader.vue';
import vCurrentCall from './currentCall.vue'
import vTelInput from './TelInput.vue'
export default {
    name: 'TelWind',
    props: {
        agentName: {
            type: String,
            default: ''
        },
        value: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        vTelInput,
        vIncomingCallList,
        vButton,
        vPreloader,
        vCurrentCall
    },
    data() {
        return {
            timer: null,
            isloading: true,
            
        }
    },
    methods: {
        handlInput(){
            // if incall set duration input 500ms
            const time = this.value.isCall ? 2000: 0
            if(this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.$emit('input', this.value)
                if(time) {
                    //this.value.telInput.phoneNumber
                    this.$emit('dtmf', this.value)
                }
            }, time);
        }
    }
}
</script>

<style lang="scss" >
@import '@scss/mixins.scss';
.tel-wind {
    display: flex;
    width: 378px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #FFF;
    
    /* Sidebar left */
    box-shadow: 4px 0px 12px 0px rgba(58, 65, 70, 0.06), 8px 0px 16px 0px rgba(58, 65, 70, 0.12);
    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        .button {
            margin-left: auto;
        }
    }
    
    &__title { 
        display: flex;
        align-items: center;
        color: #0A0A17;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        span {
            color: #919197;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            margin-left: 12px;
        }
    }
    
    &__subtitle {
        color: #0A0A17;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }
    
    &__row {
        width: 100%;
    }
    
    &__input {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
            font-size: 14px;
        }
    }
    
    & &__call {
        color: #30C66C;
        margin-left: 20px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        &-active {
            color: #e94a4a;
        }
        svg {
            margin: 0;
        }
        &.button_type_loading { span { display: none; } }
        .preloader {
            margin: 0;
        }
        &-preload {
            color: #fff;
            background-color: #30C66C;
            border-radius: 50%;
        }
        
        &-disabled { 
            color: #ccc;
        }
        
    }
}

@keyframes shrink {
  to {
    width: 0; /* конечная ширина */
  }
}
.tel-list {

    &__timer{
        background-color: #3a4146;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 5px;
        &:after{
            content: '';
            display: block;
            background-color: #f9c30c;
            height: 100%;
            animation: shrink 5s linear forwards;
            width: 100%;
        }
    }
    &__items {
        
        display: block;
        max-height: 330px;
        overflow: auto;
        @include scrollbar();
        padding-right: 10px;
        margin-right: -10px;
    }
    
    p {
        color: #919197;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
    }
    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 12px 20px;
        border-radius: 12px;
        border: 1px solid #CBCCCF;
        box-shadow: 0px 8px 24px 0px rgba(58, 65, 70, 0.06), 0px 4px 8px 0px rgba(58, 65, 70, 0.06);
        overflow: hidden;
        //height: 71px;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    &__name {
        *,a {
            color: #919197;
            font-size: 14px;
            font-weight: 600;
        }
    }
    &__number {
        display: flex;
        align-items: flex-end;
        color: #3A4146;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        &-centered { 
            margin-top: auto;
            margin-bottom: auto;
            align-items: center;
        }
    }
    
    &__buttons{
        display: flex;
        margin-left: auto;
        button {
            margin-right: 0;
            svg {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    & &__button {
        color: #30C66C;
        &-red {
            margin-right: 12px;
            color: #E94A4A;
        }
    }
}
</style>