import authRquest from '../helpers/authorizateRequest'
export default {
    getParams(params) {
        if(!params) return ''
        if(typeof params == 'string') return params

        if(params.length) {
            let _params =''
            params.forEach(param => {
                const t = '/'+this.getPeram(param)
                _params += t == '/' ? '': t
            });
            return _params.replace('//','/').replace('/?', '?')
        } else {
            return this.getPeram(params)
        }
    },

    getPeram(param) {
        if(typeof param == 'string' || typeof param == 'number') return param
        if(param.length) {
            const temp = param.reduce((a,b)=>{ return a+='/'+b }) // return /param/param2/param3 ... 
            return temp 
        } else {
            const temp= new URLSearchParams(param).toString(); // return param1=something&param2=somethingelse ... 
            return temp? '?'+temp : ''
        }
    },

    async get(payload, url, param) {
        param = this.getParams(param)
        payload = this.getParams(payload)
        const endpoint = url || DEFAULT_ENDPOINTS.dashboard

        // const { data, config } = await authRquest.get(endpoint  +  param  + payload )
        // console.log('get:', config.headers['unique-id']);
        const { data } = await authRquest.get(endpoint  +  param  + payload )
        
        return data
    },
    async put(payload, url, param) {
        param = this.getParams(param)
        const endpoint = url || DEFAULT_ENDPOINTS.dashboard
        const { data } = await authRquest.put( endpoint + param, payload)
        return data
    },
    async post(payload, url, param) {
        param = this.getParams(param)
        
        const endpoint = url || DEFAULT_ENDPOINTS.dashboard
        const { data } = await authRquest.post(endpoint + param, payload )
        return data
    },
    async delete(payload, url, param) {
        param = this.getParams(param)
        
        const endpoint = url
        const { data } = await authRquest.delete( endpoint + param, payload)
        return data
    },
}