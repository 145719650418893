<template>
    <div class="chat" :class="{'chat__loading':!contacts}">
        <c-toggle v-model="counter" v-if="this.counter" :disabled="isloading || !this.counter" :selected="$store.getters.chat_tab" @click="handleToggleClick"/>
        <div class="chat-tools" v-if="this.$store.getters.chat_tab == 'history'" >
            <v-custom-input
                class="size_large"
                v-model="search"
                :icon="'#icon-search'"
                :isload="isSearching"
                @input="handleSearchInput"
                placeholder="Введите номер телефона"
            />
            
            <v-custom-switch-input 
                on=""
                off="Мои"
                @input="handleSwitchToggle"
                v-model="historyMy"
                :size="'small'"
            />
        </div>
        
        <div class="chat-contacts" ref="contacts" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave" v-if="contacts && contacts.length">
            <template v-if="contacts.length" v-for="(contact,index) in contacts">
                <v-chat-contact :contact="contact" @click="$emit('click', $event)"/> 
            </template>
            
        </div>
        <template v-else-if="contacts && !contacts.length && this.$store.getters.chat_tab == 'history' && search">
            контакта нету или введен не полный номер телефона
        </template>
        <template v-else-if="contacts && !contacts.length && this.$store.getters.chat_tab != 'history'">
            Ничего нет
        </template>
        <v-preloader v-if="isloading" class="preloader_type_button" size="small"/>
    </div>
</template>

<script>
import api from '@api'
import cToggle from './modules/toggle'
import vChatContact from './modules/contact'
import { mapActions } from 'vuex'
import { eventBus } from '@eventBus'
import vPreloader from '../global/Preloader'
import vCustomInput from '../global/CustomInput'
import vCustomSwitchInput from '../global/CustomSwitchInput.vue'
export default {
    components: {
        cToggle,
        vChatContact,
        vPreloader,
        vCustomInput,
        vCustomSwitchInput
    },
    data () {
        return {
            counter: false,
            contacts: null,
            isloading: true,
            search: '',
            isSearching: false,
            searchInputTimer: null,
            historyMy: true
        }
    },
    mounted() {
        this.getSelectedChats()

        eventBus.$on('socketMessage', this.handleSocketNewMessage);
        eventBus.$on('socketAssignEndMessage', this.handleAssignEndMessage);
        eventBus.$on('socketNewСhat', this.handleNewChat);
        eventBus.$on('socketRemoveChat', this.handleRemoveChat);
    },
    methods: {
        ...mapActions([
            'TOGGLE_CHAT_TAB'
        ]), 
        handleSwitchToggle() {
            this.contacts = null
            this.handleSearchInput(this.search)
        },
        handleSearchInput(e) {
            const _this = this
            if(this.searchInputTimer) clearTimeout(this.searchInputTimer)
            const phone = e.replace(/[^0-9]/g, '')
            this.searchInputTimer = setTimeout(function() {
                _this.getHistory({phone, is_my: _this.historyMy}).then(res=>{
                    _this.contacts = res.response
                })
            }, 500);
        },
        getSelectedChats() {
            this.contacts = null
            this.getCounters().then(res=>{
                this.counter = res
            })
            if(this.$store.getters.chat_tab == 'history') {
                this.getHistory().then(res=>{
                    this.contacts = res.response
                })
                return
            }
            this.getContacts(this.$store.getters.chat_tab).then(res=>{
                this.contacts = res
            })
        },
        getCounters() {
            //this.isloading = true
            return api.chat.getCounts().then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                // this.isloading = false
            })
        },
        getHistory(payload) {
            this.isloading = true
            return api.chat.history(payload, '',).then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        },
        getContacts(direction) {
            this.isloading = true
            const payload = {
                direction
            }
            return api.chat.getChats(payload, '', 'post').then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
            }).finally(e=>{
                this.isloading = false
            })
        },
        handleToggleClick(selected) {
            this.TOGGLE_CHAT_TAB(selected)
            this.$nextTick(()=>{
                this.getSelectedChats()
            })
        },
        handleMouseenter() {
            const element = this.$refs.contacts
            const hasScroll = element.scrollHeight > element.clientHeight;
            if (hasScroll) {
                element.style.marginRight = '-10px'
                element.style.paddingRight = '3px'
            }
        },
        handleMouseleave() {
            const element = this.$refs.contacts
            element.style.marginRight = null
            element.style.paddingRight = null
        },
        handleSocketNewMessage(contactData) {
            const contact = this.getContactFromList(contactData)
            if(!contact) return
            const counter = contactData.unread_messages
            const {date_created, message} = contactData.message_item
            this.updateContact(contact,{ date:date_created, message, counter})
        },
        handleAssignEndMessage(contactData) {
            const contact = this.getContactFromList(contactData)
            if(!contact) return
            const counter = contactData.unread_messages
            const {date, message} = contactData
            this.updateContact(contact,{ date, message, counter})
        },
        handleNewChat(data) {
            if(this.$store.getters.chat_tab == 'all') this.contacts.push(data.chat_item)
        },
        handleRemoveChat(data) {
            if(this.$store.getters.chat_tab == 'all') {
                this.contacts.some(e=>{
                    if(e.id == data.chat_id) {
                        console.log('ЧАТ: match, ',data);
                        this.contacts.splice(this.contacts.indexOf(e), 1)
                    }
                })
            }
        },

        getContactFromList(contact) {
            if(!contact) return
            let id = null
            const {chat_id} = contact
            for (let i = 0; i < this.contacts.length; i++) {
                if (this.contacts[i].id === chat_id) {
                    id = this.contacts[i]
                    break;
                }
            }
            return id
        },
        updateContact(contact, data) {
            if(!contact) return // todo: fix вызывается 2 раза если приходит сообщение если контакт из мессенджера определить
            const {date, message, counter} = data
            contact.date_create = date,
            contact.message = message
            contact.count_unread = counter
        }
        
    }
}
</script>

<style lang="scss">
@import '../../../../gulp-src/app/scss/_variables';
@import '../../../../gulp-src/app/scss/_mixins';

.chat {
    display: flex;
    flex-direction: column;
    max-height: 100% ;
    &-toggle { 
        flex: 0 0 auto;
    }
    &-contacts {
        @include scrollbar($thumb-color:#3A4146, $track-color:#CBCCCF);
        flex: 1 1 auto;
        overflow: auto;
        margin-right: -10px;
        padding-right: 10px;  
        &:hover::-webkit-scrollbar {
            width: 11px;
        }   
    }
    &-tools {
        display: flex;
        width: 100%;
        vertical-align: middle;
        margin-bottom: 16px;

        .switcherInput{
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;
        }
    }
    &__loading {
        height: 100%;
    }
}
</style>