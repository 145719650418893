<template>
    <div class="header">
        <div class="header__left">
            <router-link :to="{name: 'dashboard'}" class="header__name" > {{user.company_name}} </router-link>
            <v-status :value="daily" v-if="daily && perm.quick_change_status_management" style="margin-left: 5px ;" size="small"/>
        </div>
        <div class="header__right">
            <v-company/>
            <v-search v-if="perm.quick_search_driver_management"/>
            <v-trans v-if="perm.quick_transaction_button_management"/>
            <v-pay v-if="perm.quick_payment_button_management" />
            <v-chat v-if="perm.quick_chat_management"/>
            <!-- <v-notification :count="user.new_notifications || 0" v-if="perm.quick_notification_management"/> -->
            <v-user v-if="user && perm" :name="user.name" :perm="perm"/>
            <!-- <v-user-status @update="HandlerStatusUpdate($event)" v-if="perm.quick_change_status_management"/> -->
        </div>

        <v-modal v-if="!!call" :show="!!call" @close="call = false">
            <p slot='header'> {{call.title}} </p>
            <div slot="body">
                <p v-if="call.candidate_info" style="font-size:20px;">
                    {{ call.candidate_info.text }}
                </p>
                <p v-if="call.driver_info" style="font-size:20px;">
                    {{ call.driver_info.text }}
                </p>
            </div>
            <div slot="footer" class="flex-right">
                <button class="button button_size_ex-large button_color_white" @click='handleReset'>Закрыть</button>
                <button class="button button_size_ex-large button_color_black" @click="handleApply(call)">Перейти</button>
            </div>
        </v-modal>
        <v-end-call-modal v-if="!!uuid" @close="uuid = false" :uuid="uuid"/>
    </div>
</template>

<script> 
import api from '@api'
import { eventBus } from '@eventBus'
import { mapState, mapActions } from 'vuex'
import chatsocket from './socket'
import vEndCallModal from './modules/vEndCallModal.vue'
import storeReset from '../../router/views/storeReset'
import telSocket from './socket_tyelephony'
import socket_table from './socket_table'
import vChat from './modules/HeaderChatNotification' 
import vCompany from './modules/Company'
import VModal from '@components/global/Modal'
import vNotification from './modules/HeaderNotification' 
import vPay from './modules/HeaderQuickPay'
import vSearch from './modules/HeaderQuickSearch'
import vTrans from './modules/HeaderTransactions'
import vUser from './modules/HeaderUser'
import vUserStatus from './modules/HeaderUserStatus'

import vStatus from '../../router/views/payments/modules/status'

export default {
    name:'vHeader',
    components:{ 
        vChat,
        vNotification, 
        vUser,
        vUserStatus,
        vPay,
        vTrans,
        vSearch,
        vCompany,
        VModal,
        vEndCallModal,
        vStatus
    },
    computed: {
        ...mapState({
            user: state => state.profile.user,
            perm: state => state.profile.perm
        }),
    },
    mixins: [chatsocket,storeReset,telSocket, socket_table],
    data() {
        return {
            status: [],
            userMenu: [],
            isEndCall: false,
            uuid: '',
            call: null,
            daily: null,
            timer: null
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    }, 
    mounted() {
        window.addEventListener("resize", this.myEventHandler);
        window.dispatchEvent(new Event('resize'));
        eventBus.$on('incomingCall', this.incomingCall);
        eventBus.$on('endCall', this.endCall);
        
        eventBus.$on('readyReport', this.socketReadyReport);
        eventBus.$on('errorReport', this.socketerrorReport);
        this.getRef()
        this.initStatus()
    },
    methods: {
        ...mapActions([
            'GET_REFRENCES',
            'UPDATE_PROFILE'
        ]),
        socketReadyReport(e) {
            this.$toast.success(e.message)
        },
        socketerrorReport(e) {
            this.$toast.error(e.message)
        },
        HandlerStatusUpdate(status) {
            this.UPDATE_PROFILE({status})
        }, 
        
        getRef() {
            this.GET_REFRENCES().then(response=>{
                this.initSocket()
                this.tel_initSocket()
                this.tab_initSocket()
            }).catch(error=>{
                console.error(error);
                if(error.code == 401) {
                    eventBus.$emit('globalLogout')  
                } 
            })
        },
        initStatus() { 
            const _this = this
            _this.getSummary().then(res=>{
                _this.daily = res.daily
            }) 

            this.timer = setInterval(function() {
                //console.log('initStatus');
                _this.getSummary().then(res=>{
                    _this.daily = res.daily
                }) 
            }, 20000);
        },
        getSummary(payload) {
            this.isloading = false
            return api.payments.summary(payload).then(response=>{
                return response
            }).catch(error=>{
                this.$toast.error(error.message)
                if(this.timer) clearInterval(this.timer)
            }).finally(e=>{
                this.isloading = false
            })
        },
        myEventHandler(e) {
            if(this.headerHeight != this.$el.clientHeight) {
                this.headerHeight = this.$el.clientHeight
                this.$emit('headerHeightChange',this.headerHeight)
            }
        },
        incomingCall(e) {
            //console.log('incomingCall', {e});
            const {action,type,driver_info,candidate_info,company_id,user_id,uuid,title} = e
            this.call = {action,type,driver_info,candidate_info,company_id,user_id,uuid,title}
        },
        handleApply(call) {
            //console.log('call', {call});
            let route = null
            if(call.type == 'candidate') {
                const {id} = call.candidate_info
                route = {name: 'CandidateEdit', query: {id}}      
            } else {
                const {id,park_id} = call.driver_info
                route = {name: "DriverEdit", query: { driver_id:id, park_id }}
            }
            this.$router.push(route)
            this.call = null
        },
        handleReset() {
          this.call = null
        },
        endCall(e) {
            this.uuid = e.uuid
        }
    },
    
}
</script>

<style lang="scss">
@import '../../../../gulp-src/app/scss/_variables';

.header{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    padding-left: 52px;
    //height: 64px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #CBCCCF;
    background-color: #fff;
    
    
    @media (min-width: 1500px) {
        padding-left: 82px;
    }
    
    &__right {
        flex:1 1 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 12px;
        padding-left: 12px;
        background: $global-white;
        
        @media (min-width: 1500px) {
            padding-right: 32px;
            padding-left: 32px;
        }
    }
    
    &__left {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        padding-right: 12px;
        padding-left: 12px;
        
        @media (min-width: 1500px) {
            padding-right: 32px;
            padding-left: 32px;
        }
    }
    
    &__name {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #0A0A17; 
    }
    &_button_icon {
        display: flex;
        align-items: center;
    }
}
</style>